import config from '#config';

import cookies from '#utils/cookies';

const firstClick = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dm = [config.BRAND];
  const url = `/first-click`;

  const getType = (domain: string) =>
    dm.some((element) => element?.toLowerCase() === domain?.toLowerCase());

  try {
    let count = 0;
    let pooling = true;

    const stop = (acceptCookie = false) => {
      if (acceptCookie) {
        cookies.set('_stvst', 'st', { expirationMinutes: 30 });
      }
      pooling = false;
      return acceptCookie;
    };

    const sendInfo = async (body: Object) => {
      try {
        const options = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const response = await fetch(url, options);
        const successCookie = response?.status === 200;
        return stop(successCookie);
      } catch (error) {
        console.log(error);
        return stop(false);
      }
    };

    const prepareConstructor = async () =>
      new Promise((resolve) => {
        // eslint-disable-next-line consistent-return
        setTimeout(async () => {
          const shopSequence = cookies.get('sequence');
          let gaClientId = cookies.get('_ga');
          const transactionId = urlParams.get('transaction_id');
          const visit = cookies.get('_stvst') && !transactionId;
          count += 1;
          if (count > 2 && (!gaClientId || gaClientId === 'undefined')) {
            gaClientId = '';
          }

          if (!shopSequence || !gaClientId || gaClientId === 'undefined') {
            return resolve(false);
          }
          const affId = urlParams.get('aff_id') && urlParams.get('aff_id')?.substring(3);
          const goal = urlParams.get('goal') || 'rs';

          const htmlLang = document.querySelector('body')?.getAttribute('data-country');
          const paramsLang = urlParams.get('c');
          const country = `${paramsLang}` === `None` || !paramsLang ? htmlLang : paramsLang;
          const referrer = document.referrer !== '' ? document.referrer : undefined;
          const isAff = affId
            ? { aff_id: affId, country, goal, transaction_id: transactionId }
            : {};
          const body = {
            shop_sequence: shopSequence,
            ga_client_id: gaClientId,
            brand: config.BRAND,
            domain: window.location.host,
            ...isAff,
          };
          if (visit) {
            return resolve(stop(false));
          }
          if (affId) {
            return resolve(await sendInfo(body));
          }

          if (!referrer && !visit) {
            return resolve(await sendInfo(body));
          }
          if (referrer) {
            if (getType(referrer)) {
              return resolve(stop(false));
            }
            return resolve(await sendInfo(body));
          }
        }, 700);
      });

    const range = {
      from: 0,
      to: 2,
      [Symbol.asyncIterator]() {
        return {
          current: this.from,
          last: this.to,
          async next() {
            await prepareConstructor();
            if (this.current < this.last && pooling) {
              return { done: false, value: this.current + 1 };
            }
            return { done: true };
          },
        };
      },
    };
    // eslint-disable-next-line
    for await (const value of range) {
    }
  } catch (e) {
    console.log(e);
  }
};

export default firstClick;
